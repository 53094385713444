import React from "react"
import { useIntl, FormattedMessage, Link } from "gatsby-plugin-intl"
import Seo from "../../../components/SEO"
import Header from "../../../components/Header"
import PageNav from "../../../components/PageNav"

export default function Texts() {
  const intl = useIntl()

  return (
    <div>
      <Seo title={intl.formatMessage({ id: "texts.title.seo" })} />
      <Header />

      <div className="sir-breadcrumbs">
        <Link class="text-sir-black-50" to="/"><FormattedMessage id="global.sir" /></Link>
        <span class="text-sir-black-25 mx-1">/</span>
        <Link class="text-sir-black-50" to="/great-love"><FormattedMessage id="global.menu.greatlove" /></Link>
        <span class="text-sir-black-25 mx-1">/</span>
        <FormattedMessage id="global.menu.texts" />
      </div>


      <div className="sir-section sir-layout-2-3 text-center">
        <h1 className="title-l uppercase mb-4">
          <FormattedMessage id="texts.title" />
        </h1>
        <p className="body-l md:w-2/3 mx-auto"><FormattedMessage id="greatlove.press.text1" /></p>
      </div>



      <div className="sir-section sir-layout-2-3">
        <div className="w-full flex justify-start">
          <div className="md:w-1/2 mb-16">
            <Link to="/great-love/texts/laurits">
              <p className="body-s uppercase mb-4"><FormattedMessage id="texts.laurits.author" /></p>
              <h2 className="title uppercase"><FormattedMessage id="texts.laurits.title" /></h2>
            </Link>
          </div>
        </div>

        <div className="w-full flex justify-end">
          <div className="md:w-1/2 mb-16">
            <Link to="/great-love/texts/lamp">
              <p className="body-s uppercase mb-4"><FormattedMessage id="texts.lamp.author" /></p>
              <h2 className="title uppercase"><FormattedMessage id="texts.lamp.title" /></h2>
            </Link>
          </div>
        </div>

        <div className="w-full flex justify-start">
          <div className="md:w-1/2 mb-16">
            <Link to="/great-love/texts/kangro">
              <p className="body-s uppercase mb-4"><FormattedMessage id="texts.kangro.author" /></p>
              <h2 className="title uppercase"><FormattedMessage id="texts.kangro.title" /></h2>
            </Link>
          </div>
        </div>

        <div className="w-full flex justify-end">
          <div className="md:w-1/2 mb-16">
            <Link to="/great-love/texts/kiwa">
              <p className="body-s uppercase mb-4"><FormattedMessage id="texts.kiwa.author" /></p>
              <h2 className="title uppercase"><FormattedMessage id="texts.kiwa.title" /></h2>
            </Link>
          </div>
        </div>

        <div className="w-full flex justify-start">
          <div className="w-1/2 mb-16">
            <Link to="/great-love/texts/lopp">
              <p className="body-s uppercase mb-4"><FormattedMessage id="texts.lopp.author" /></p>
              <h2 className="title uppercase"><FormattedMessage id="texts.lopp.title" /></h2>
            </Link>
          </div>
        </div>

        <div className="w-full flex justify-end">
          <div className="md:w-1/2 mb-16">
            <Link to="/great-love/texts/sepp-sild">
              <p className="body-s uppercase mb-4"><FormattedMessage id="texts.seppsild.author" /></p>
              <h2 className="title uppercase"><FormattedMessage id="texts.seppsild.title" /></h2>
            </Link>
          </div>
        </div>

      </div>

      <PageNav />
    </div>
  )
}
